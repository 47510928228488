import { Injectable } from '@angular/core';
import { Header } from 'src/app/classes/Header';
import { LocalStorage } from '@ngx-pwa/local-storage';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  myStorage = window.localStorage;

  constructor(private localStorage: LocalStorage) { }

  setIdentity(identity: any): void {
    if (identity) {
      this.myStorage.setItem('identity', JSON.stringify(identity));
    }
  }

  removeIdentity(): void {
    this.myStorage.removeItem('identity');
  }

  getIdentity(): any {
    const identity: string = this.myStorage.getItem('identity');
    return identity ? JSON.parse(identity) : null;
  }

  getUserId(): number {
    const identity: string = this.myStorage.getItem('identity');
    if (identity) {
      const userId = JSON.parse(identity).userId;
      if (userId) {
        return +userId;
      }
    }
    return null;
  }

  isAuthenticated(): boolean {
    const identity: string = this.myStorage.getItem('identity');
    if (identity) {
      return true;
    }
    return false;
  }

  getUserName(): string {
    const identity: string = this.myStorage.getItem('identity');
    if (identity) {
      const userName = JSON.parse(identity).userName;
      if (userName) {
        return userName;
      }
    }
    return null;
  }

  getRoles() {
    const identity: string = this.myStorage.getItem('identity');
    if (identity) {
      const roles = JSON.parse(identity).roles;
      if (roles) {
        return roles;
      }
    }
    return null;
  }

  getPost(): string {
    const identity: string = this.myStorage.getItem('identity');
    if (identity) {
      const userName = JSON.parse(identity).post;
      if (userName) {
        return userName;
      }
    }
    return null;
  }

  getEnvironment(): string {
    const identity: string = this.myStorage.getItem('identity');
    if (identity) {
      const env = JSON.parse(identity).env;
      if (env) {
        return env;
      }
    }
    return null;
  }

  getToken(): any {
    const identity: string = this.myStorage.getItem('identity');
    if (identity) {
      const token = JSON.parse(identity).accessToken;
      if (token) {
        return token;
      }
    }
    return null;
  }

}
